// import packages
import React, {useEffect, useState} from "react";
import {connect} from 'react-redux';
import isEmail from "validator/es/lib/isEmail";

//Import assets
import './Contact.scss';

//Import Components

// Import utils
import {getPropsFromState} from "../../redux/mapStateToProps";
import {SendEmail} from "../../redux/actions";
import {FullscreenControl, Map, Placemark, TypeSelector, YMaps} from "@pbe/react-yandex-maps";
import {LoadingOutlined} from "@ant-design/icons";
import {ContactCallIcon, ContactLocationIcon, ContactMessageIcon, ContactPageImg} from "../../assets/images";
import {InputGroup} from "../../components/uiElements/inputGroup/InputGroup";
import Subscribe from "../../components/subscribe/Subscribe";

function Contact(props){
    const {staticTexts,contacts,requestLoading} = props
    const [fieldsData,setFieldsData] = useState({
        firstName:'',
        lastName:'',
        email:'',
        phone:'',
        message:'',
    })
    const [error,setError] = useState({
        firstName:false,
        lastName:false,
        email:false,
        phone:false,
        message:false,
    })

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    },[])

    function validate() {
        const error = {};
        let result = true;
        Object.keys(fieldsData).forEach(key => {
            if (!fieldsData[key] || (key === 'email' && !isEmail(fieldsData?.email)) ||
                (key === 'phone' && fieldsData.phone.length < 5 )){
                error[key] = true;
                result = false;
            }

        })
        setError(error);
        return result;
    }
    function sendVisit() {
        if(validate()){
            props.SendEmail(fieldsData).then(() => {
            }).finally(() => {
                setFieldsData({
                    firstName:'',
                    lastName:'',
                    email:'',
                    phone:'',
                    message:'',
                })
            })
        }
    }
    const mapData = {
        center: [contacts?.location?.latitude, contacts?.location?.longitude],
        zoom: 16,
    }

    function getInputValues(e) {
        const {name, value} = e.target
        if (name === "phone" && value && !/^[+\d]\d*$/.test(value)) {
            return
        }
        setFieldsData({
            ...fieldsData,
            [name]: value
        })
        setError({
            ...error,
            [name]: false
        })
    }

    return <div className={'contacts-page-wrapper'}>
        <div className={'contacts-block'}>
            <div className={'contacts-main-block'}>
                <div className={'contacts-main-text'}>
                    <div className={'main-title'}>
                        {staticTexts?.contact_title}
                    </div>
                    <div className={'main-description'}>
                        {staticTexts?.contact_description}
                    </div>
                </div>
                <div className={'contacts-inputs-wrapper'}>
                    <div className={'fullName-inputs'}>
                        <InputGroup
                            inputType={'input'}
                            type={"text"}
                            value={fieldsData.firstName}
                            error={error.firstName}
                            name={'firstName'}
                            placeholder={staticTexts?.contacts_page_placeholder_name}
                            label={staticTexts?.contacts_page_label_name}
                            maxLength={100}
                            onChange={getInputValues}
                        />
                        <InputGroup
                            inputType={'input'}
                            type={"text"}
                            value={fieldsData.lastName}
                            error={error.lastName}
                            name={'lastName'}
                            placeholder={staticTexts?.contacts_page_placeholder_lastName}
                            label={staticTexts?.contacts_page_label_lastName}
                            maxLength={100}
                            onChange={getInputValues}
                        />
                    </div>
                    <div className={'inputs'}>
                        <InputGroup
                            inputType={'input'}
                            type={"text"}
                            value={fieldsData.email}
                            error={error.email}
                            name={'email'}
                            placeholder={staticTexts?.contacts_page_placeholder_email}
                            label={staticTexts?.contacts_page_label_email}
                            maxLength={100}
                            onChange={getInputValues}
                        />
                        <InputGroup
                            inputType={'input'}
                            type={"text"}
                            value={fieldsData.phone}
                            error={error.phone}
                            name={'phone'}
                            placeholder={staticTexts?.contacts_page_placeholder_phoneNumber}
                            label={staticTexts?.contacts_page_label_phoneNumber}
                            maxLength={100}
                            onChange={getInputValues}
                        />
                        <InputGroup
                            inputType={'textarea'}
                            type={"text"}
                            value={fieldsData.message}
                            error={error.message}
                            name={'message'}
                            placeholder={staticTexts?.contacts_page_placeholder_message}
                            label={staticTexts?.contacts_page_label_message}
                            // maxLength={100}
                            onChange={getInputValues}
                        />
                    </div>
                </div>
                <button className='sent-email'
                        onClick={() => {
                            if (!requestLoading && sendVisit) {
                                sendVisit()
                            }
                        }}>
                    {staticTexts?.contacts_page_btn_send}
                    {
                        requestLoading ? <LoadingOutlined style={{fontSize: 14, color: "#fff"}}/>
                            : ''
                    }

                </button>
            </div>
            <div className={'contact-map-block'}>
                <YMaps query={{
                    apikey: '83841bdc-892a-431f-a9ff-b378ef38c6a0',
                    lang: 'en'
                }} >
                    <Map defaultState={mapData} cursors={"inherit"} className={'yandexMap'} >
                        <Placemark
                            geometry={[contacts?.location?.latitude, contacts?.location?.longitude]}
                            classname={'customIcon'}
                            options={{
                                iconImageOffset: [-15, -60],
                            }}
                        />
                        <FullscreenControl options={{
                            position:{
                                bottom:28,
                                right: 26,
                            },
                        }}/>
                        <TypeSelector options={{
                            float: 'right'
                        }} />
                    </Map>
                </YMaps>
            </div>
        </div>
        <div className={'information-block'}>
            <div className={'information-block-header'}>
                <div className={'information-block-title'}>
                    {staticTexts?.contact_info_title}
                </div>
                <div className={'information-block-description'}>
                    {staticTexts?.contact_info_description}
                </div>
            </div>
            <div className={'contact-info-wrapper'}>
                <div className={'contact-info'}>
                    <div className={'info-icon'}><ContactLocationIcon/></div>
                    <div className={'info-title'}>
                        {staticTexts?.contact_address_title}
                    </div>
                    <div className={'wrapper'}>
                        <div className={'information'}>
                            {contacts?.address1}
                        </div>
                        <div className={'information'}>
                            {contacts?.address2}
                        </div>
                    </div>
                </div>
                <div className={'contact-info'}>
                    <a href={`tel:${contacts?.phoneNumbers}`} className={'info-icon'}>
                        <ContactCallIcon/>
                    </a>
                    <div className={'info-title'}>
                        {staticTexts?.contact_number_title}
                    </div>
                    <div className={'wrapper'}>
                        {contacts?.phoneNumbers?.length && contacts?.phoneNumbers.map(item => {
                            return <a href={`tel:${item}`} className={'information'}>
                                {item}
                            </a>
                        })}
                    </div>
                </div>
                <div className={'contact-info'}>
                    <a href={`mailto:${contacts?.email}`} className={'info-icon'}>
                        <ContactMessageIcon/>
                    </a>
                    <div className={'info-title'}>
                        {staticTexts?.contact_email_title}
                    </div>
                    <div className={'wrapper'}>
                        <a href={`mailto:${contacts?.email}`} className={'information'}>
                            {contacts?.email}
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div className={'contact-page-img'}>
            <img src={ContactPageImg} alt=""/>
        </div>
        <Subscribe requestLoading={requestLoading} staticTexts={staticTexts}/>
    </div>
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'staticTexts',
        'contacts',
        'requestLoading',
    ])
};

const mapDispatchToProps = {
    SendEmail,
};

export default connect(mapStateToProps, mapDispatchToProps)(Contact);
