// import packages
import React, {useEffect, useState} from 'react';

//Import Components

//Import assets
import "./NewsModal.scss"

// Import utils
import {connect} from "react-redux";
import {getPropsFromState} from "../../../../redux/mapStateToProps";
import {Modal} from "antd";
import {generateImageMediaUrl} from "../../../../utils/generateMediaUrl";
import moment from "moment";
import {ModalCloseIcon} from "../../../../assets/images";


function NewsModal(props){
    const {modalOpen,toggleModalOpen,news} = props
    const [img ,setImg] = useState('')

    useEffect(() => {
        setImg(news?.mediaMain?.path)
    },[news])

    function closeModal(){
        toggleModalOpen()
        setImg('')
    }

    return <Modal centered
                  title={''}
                  visible={modalOpen}
                  closeIcon={<ModalCloseIcon/>}
                  className={'newsModal'}
                  onCancel={closeModal}>
        <div className={'news-modal-wrapper'}>
            {
                img && <div className={'news-block-img'}>
                    <img src={generateImageMediaUrl(img)}/>
                </div>
            }
            <div className={'news-block-info'}>
                <div className={'news-date'}>
                    {moment(news?.createdAt).format('ll')}
                </div>
                <div className={'news-block-title'}>
                    {news?.title}
                </div>
                <div className={'news-block-description'}>
                    {news?.description}
                </div>
            </div>
        </div>
    </Modal>
}
const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'staticTexts',
        'requestLoading',
    ])
};

const mapDispatchToProps = {
};
export default connect(mapStateToProps, mapDispatchToProps)(NewsModal)