// Import packages
import React, {memo} from "react";
import {connect} from 'react-redux';
import {Link, NavLink} from "react-router-dom";

// Import assets
import './mobileMenu.scss';

// Import components
import {getPropsFromState} from "../../redux/mapStateToProps";
import {ChangeLanguage} from "../../redux/actions";

// Import utils



const MobileMenu = memo((props) => {
    const {className, staticTexts, toggleMobileMenu,languages,selectedLanguage} = props;


    return <section className={`right-menu-wrapper ${className}`}>
        <div className={`right-menu-content ${className}`}>
            <nav className='mobile-nav'>
                <NavLink to={'/about'} activeClassName='active'>
                    <div className="nav-item" onClick={toggleMobileMenu}>{staticTexts?.header_item_about}</div>
                </NavLink>
                <NavLink to={'/services'} activeClassName='active'>
                    <div className="nav-item" onClick={toggleMobileMenu}>{staticTexts?.header_item_services}</div>
                </NavLink>
                <NavLink to={'/news'} activeClassName='active'>
                    <div className="nav-item" onClick={toggleMobileMenu}>{staticTexts?.header_item_news}</div>
                </NavLink>
                <NavLink to={'/products'} activeClassName='active'>
                    <div className="nav-item" onClick={toggleMobileMenu}>{staticTexts?.header_item_products}</div>
                </NavLink>
                <NavLink to={'/contact'} activeClassName='active'>
                    <div className="nav-item" onClick={toggleMobileMenu}>{staticTexts?.header_item_contact}</div>
                </NavLink>
                <div className="language-items">
                    {
                        languages?.length && languages?.map((item) => {
                            return <div key={item.id}
                                        onClick={() => {
                                            props.ChangeLanguage(item)
                                        }}
                                        className={'language-dropdown-item'}>
                                <div className={`selected-language 
                                    ${selectedLanguage?.code === item.code ? 'active-language' : ''}`}>
                                    {item?.name}
                                </div>
                            </div>
                        })
                    }
                </div>
            </nav>
        </div>
        <div className={`mobile-menu-overlay`} onClick={toggleMobileMenu}/>
    </section>
})

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'staticTexts',
        'contacts',
        'languages',
        'selectedLanguage',
        'services',
    ])
};
const mapDispatchToProps = {
    ChangeLanguage
};

export default connect(mapStateToProps, mapDispatchToProps)(MobileMenu);