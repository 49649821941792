import {HOST_MEDIA_URL_V1} from '../redux/api'
import defaultLogo from '../assets/images/empty_img.jpg';

export function generateImageMediaUrl(path) {
    if (path?.startsWith('http')) {
        return path;
    }
    return path ? `${HOST_MEDIA_URL_V1}/files${path}` : defaultLogo;
}

export function generateFileMediaUrl(path) {
    if (path?.startsWith('http')){
        return path;
    }
    return path ? `${HOST_MEDIA_URL_V1}/files${path}` : defaultLogo;
}
