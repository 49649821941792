import './services.scss'
import {getPropsFromState} from "../../redux/mapStateToProps";
import {connect} from "react-redux";
import {GetServices} from "../../redux/actions";
import {useEffect} from "react";
import {generateFileMediaUrl, generateImageMediaUrl} from "../../utils/generateMediaUrl";
import HomePartners from "../../components/homepage/partners/HomePartners";
import {ArrIcon, LeftBgIcon, RightBgIcon} from "../../assets/images";
import Subscribe from "../../components/subscribe/Subscribe";
import {useState} from "react";
import React from "react";
import ServiceModal from "../../components/uiElements/modal/serviceModal/ServiceModal";

function Services(props){
    const {services,partners,staticTexts} = props
    const [modalOpen,setModalOpen] = useState(false)
    const [service,setService] = useState('')
    const [date,setDate] = useState('')

    function toggleModalOpen(service){
        setModalOpen(!modalOpen)
        setDate(service)
    }

    useEffect(() => {
        if(modalOpen){
            setService(date)
        }else {
            setService('')
        }
    },[modalOpen])

    useEffect(() => {
        props.GetServices()
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    },[])

    return <div className={'service-wrapper'}>
        <div className={'services-header'}>
            <div className={'services-header-info'}>
                <div className={'services-header-title'}>
                    {services?.title}
                </div>
                <div className={'services-header-description'}>
                    {services?.description}
                </div>
            </div>
            <div className={'services-header-img'}>
                <div className={'img-wrapper'}>
                    <img src={generateFileMediaUrl(services?.mediaMain?.path)} alt=""/>
                </div>
            </div>
        </div>
        <HomePartners partners={partners}/>
        <div className={'services-blocks-wrapper'}>
            <div className={'services-blocks-title'}>
                {services?.subTitle}
            </div>
            <div className={'services-blocks-description'}>
                {services?.subDescription}
            </div>
            <div className={'services-blocks'}>
                {
                    services?.blocks?.length && services?.blocks?.map((item,index) => {
                        return <div className={'blocks-wrapper'} key={index}>
                            <div className={'blocks-info-wrapper'}>
                                <div className={'blocks-info-title'}>
                                    {item?.title}
                                </div>
                                <div className={'blocks-info-description'}>
                                    {item?.description}
                                </div>
                                <div className={'blocks-info-more'} onClick={() => toggleModalOpen(item)}>
                                    {staticTexts?.service_more} <ArrIcon/>
                                </div>
                            </div>
                            <div className={'blocks-img-wrapper'}>
                                <div className={'blocks-img'}>
                                    <img src={generateImageMediaUrl(item?.mediaMain?.path)}/>
                                </div>
                            </div>
                            <div className={'blocks-leftBg-wrapper'}>
                                <LeftBgIcon/>
                            </div>
                            <div className={'blocks-rightBg-wrapper'}>
                                <RightBgIcon/>
                            </div>
                        </div>
                    })
                }
            </div>
            <Subscribe/>
        </div>
        <ServiceModal toggleModalOpen={toggleModalOpen} modalOpen={modalOpen} service={service}/>
    </div>
}const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'requestLoading',
        'staticTexts',
        'services',
        'partners',
    ])
};

const mapDispatchToProps = {
    GetServices
};


export default connect(mapStateToProps, mapDispatchToProps)(Services);