import './HomeNews.scss'
import {InputGroup} from "../../uiElements/inputGroup/InputGroup";
import {LoadingOutlined} from "@ant-design/icons";
import {getPropsFromState} from "../../../redux/mapStateToProps";
import {sendSubscribe} from "../../../redux/actions";
import {connect} from "react-redux";
import {useEffect, useState} from "react";
import isEmail from "validator/es/lib/isEmail";
import NewsCard from "../../uiElements/card/newsCard/NewsCard";

function HomeNews(props) {
    const {staticTexts,news,requestLoading} = props
    const [email, setEmail] = useState('');
    const [error, setError] = useState(false);

    const getInputValues = (e) => {
        const {name, value} = e.target;
        setEmail(value);
        setError(false)
    };

    const sendSubscribe = () => {
        if (email && isEmail(email)) {
            props.sendSubscribe({email}).then(() => {
                setEmail(email)
            }, errorEmail => setError(!errorEmail)).finally(() => {
                setEmail('')
            })
        } else {
            setError(true)
        }
    }
    useEffect(()=> {
        setEmail("")
        setError(false)
    },[requestLoading])

    return <div className={'homeNews-wrapper'}>
        <div className={'homeNews-header'}>
            <div className={'homeNews-info'}>
                <div className={'homeNews-title'}>
                    {staticTexts?.homepage_news_title}
                </div>
                <div className={'homeNews-description'}>
                    {staticTexts?.homepage_news_description}
                </div>
            </div>
            <div className={`homeNews-input-wrapper`}>
                <InputGroup
                    inputType={'input'}
                    type={"text"}
                    value={email}
                    error={error}
                    name={'email'}
                    placeholder={staticTexts?.homepage_contact_placeholder_email}
                    maxLength={100}
                    onChange={getInputValues}
                />

                <button className='sent-email'
                        onClick={() => {
                            if (!requestLoading && sendSubscribe) {
                                sendSubscribe()
                            }
                        }}>
                    {staticTexts?.subscribe_btn}
                    {
                        requestLoading ? <LoadingOutlined style={{fontSize: 14, color: "#fff"}}/>
                            : ''
                    }

                </button>
            </div>
        </div>
        <div className={'homeNews-blocks'}>
            {
                news?.itemsList?.length && news?.itemsList?.map(item => {
                    return <NewsCard item={item} key={item.id}/>
                })
            }
        </div>
    </div>
}const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'staticTexts',
    ])
};
const mapDispatchToProps = {
    sendSubscribe,
};
export default connect(mapStateToProps,mapDispatchToProps)(HomeNews);