// import packages
import React from 'react';

//Import Components

//Import assets
import "./ServiceModal.scss"

// Import utils
import {connect} from "react-redux";
import {SendCall} from "../../../../redux/actions";
import {getPropsFromState} from "../../../../redux/mapStateToProps";
import {Modal} from "antd";
import {generateImageMediaUrl} from "../../../../utils/generateMediaUrl";
import {ModalCloseIcon} from "../../../../assets/images";
import {InputGroup} from "../../inputGroup/InputGroup";
import {LoadingOutlined} from "@ant-design/icons";
import isEmail from "validator/es/lib/isEmail";
import {useState,useEffect} from "react";


function NewsModal(props){
    const {modalOpen,toggleModalOpen,service,staticTexts,requestLoading} = props
    const [img ,setImg] = useState('')
    const [fieldsData,setFieldsData] = useState({
        fullName:'',
        phoneNumber:'',
        email:'',
    })
    const [error,setError] = useState({
        fullName:false,
        phoneNumber:false,
        email:false,
    })

    useEffect(() => {
        setImg(service?.mediaMain?.path)
    },[service])


    function closeModal(){
        toggleModalOpen()
        setImg('')
    }

    function validate() {
        const error = {};
        let result = true;
        Object.keys(fieldsData).forEach(key => {
            if (!fieldsData[key] || (key === 'email' && !isEmail(fieldsData?.email)) ||
                (key === 'phoneNumber' && fieldsData.phoneNumber.length < 5 )){
                error[key] = true;
                result = false;
            }

        })
        setError(error);
        return result;
    }

    function sendVisit() {
        if(validate()){
            props.SendCall(fieldsData).then(() => {
            }).finally(() => {
                setFieldsData({
                    fullName:'',
                    phoneNumber:'',
                    email:'',
                })
                closeModal()
            })
        }
    }

    function getInputValues(e) {
        const {name, value} = e.target
        if (name === "phoneNumber" && value && !/^[+\d]\d*$/.test(value)) {
            return
        }
        setFieldsData({
            ...fieldsData,
            [name]: value
        })
        setError({
            ...error,
            [name]: false
        })
    }


    return <Modal centered
                  title={''}
                  visible={modalOpen}
                  closeIcon={<ModalCloseIcon/>}
                  className={'serviceModal'}
                  onCancel={closeModal}>
        <div className={'service-modal-wrapper'}>
            {
                 <div className={'service-block-img'}>
                    <img src={generateImageMediaUrl(modalOpen ? img : '')}/>
                </div>
            }
            <div className={'service-block-info'}>
                <div className={'service-block-title'}>
                    {service?.title}
                </div>
                <div className={'service-block-description'} dangerouslySetInnerHTML={{__html: service?.details}}/>
            </div>
            <div className={'service-modal-input-wrapper'}>
                <div className={'service-modal-input-title'}>
                    {staticTexts?.service_modal_feedback_title}
                </div>
                <div className={'service-modal-input-description'}>
                    {staticTexts?.service_modal_feedback_description}
                </div>
                <div className={'service-modal-inputs'}>
                    <InputGroup
                        inputType={'input'}
                        type={"text"}
                        value={fieldsData?.fullName}
                        error={error?.fullName}
                        name={'fullName'}
                        placeholder={staticTexts?.about_us_feedback_name_placeholder}
                        label={staticTexts?.about_us_feedback_name_label}
                        maxLength={100}
                        onChange={getInputValues}
                    />
                    <InputGroup
                        inputType={'input'}
                        type={"text"}
                        value={fieldsData?.email}
                        error={error?.email}
                        name={'email'}
                        placeholder={staticTexts?.homepage_contact_placeholder_email}
                        label={staticTexts?.homepage_contact_placeholder_email}
                        maxLength={100}
                        onChange={getInputValues}
                    />
                    <InputGroup
                        inputType={'input'}
                        type={"text"}
                        value={fieldsData?.phoneNumber}
                        error={error?.phoneNumber}
                        name={'phoneNumber'}
                        label={staticTexts?.about_us_feedback_phoneNumber_label}
                        placeholder={staticTexts?.about_us_feedback_phoneNumber_placeholder}
                        maxLength={100}
                        onChange={getInputValues}
                    />

                    <button className='sent-email'
                            onClick={() => {
                                if (!requestLoading && sendVisit) {
                                    sendVisit()
                                }
                            }}>
                        {staticTexts?.about_us_feedback_btn}
                        {
                            requestLoading ? <LoadingOutlined style={{fontSize: 14, color: "#fff"}}/>
                                : ''
                        }

                    </button>
                </div>
            </div>
        </div>
    </Modal>
}
const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'staticTexts',
        'requestLoading',
    ])
};

const mapDispatchToProps = {
    SendCall
};
export default connect(mapStateToProps, mapDispatchToProps)(NewsModal)