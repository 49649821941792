import './HomePackage.scss'
import {
    AddIcon,
    BackgroundIcon,
    BackgroundImg,
    BlockIcon,
    ChartCircleIcon,
    ChartIcon,
    PythonIcon
} from "../../../assets/images";

function HomePackage(props) {
    const {staticTexts} = props
    const Package = [
        {
            title: 'Հետազոտում և փորձաքննություն',
            icon: ChartIcon,
        },
        {
            title: 'Հետազոտում և փորձաքննություն',
            icon: ChartCircleIcon,
        },
        {
            title: 'Հետազոտում և փորձաքննություն',
            icon: PythonIcon,
        },
        {
            title: 'Տեղադրում և թեստավորում',
            icon: AddIcon,
        },
    ]

    return <div className={'homePackage-wrapper'}>
        <div className={'homePackage-blocks'}>
            {
                Package?.length && Package?.map(item => {
                    return <div className={'homePackage-card'}>
                        <div className={'homePackage-card-title'}>
                            {item?.title}
                        </div>
                        <div className={'homePackage-icon'}>
                            <img src={item?.icon} alt=""/>
                        </div>
                    </div>
                })
            }
            <div className={'background-responsive-img'}>
                <img src={BackgroundImg} alt=""/>
            </div>
        </div>
        <div className={'homePackage-info'}>
            <BlockIcon/>
            <div className={'homePackage-title'}>
                {staticTexts?.homepage_homePackage_title}
            </div>
            <div className={'homePackage-description'}>
                {staticTexts?.homepage_homePackage_description}
            </div>
            <div className={'background-img'}>
                <img src={BackgroundImg} alt=""/>
            </div>
        </div>
    </div>

}

export default HomePackage