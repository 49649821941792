import './HomeServices.scss'
import {generateImageMediaUrl} from "../../../utils/generateMediaUrl";
import {ArrIcon} from "../../../assets/images";
import {Link} from "react-router-dom";
import {useEffect, useState} from "react";
import ServiceModal from "../../uiElements/modal/serviceModal/ServiceModal";
import React from "react";

function HomeServices(props) {
    const {services,staticTexts} = props
    const [modalOpen,setModalOpen] = useState(false)
    const [service,setService] = useState('')
    const [date,setDate] = useState('')

    function toggleModalOpen(service){
        setModalOpen(!modalOpen)
        setDate(service)
    }

    useEffect(() => {
        if(modalOpen){
            setService(date)
        }else {
            setService('')
        }
    },[modalOpen])

    return <div className={'homeService-wrapper'}>
        <div className={'homeService-title'}>
            {services?.title}
        </div>
        <div className={'homeService-description'}>
            {services?.description}
        </div>
        <div className={'homeService-blocks'}>
            {
                services?.blocks?.length && services?.blocks?.slice(0, 5)?.map(item => {
                    return <div className={'homeService-card'}>
                        <div className={'homeService-icon'}>
                            <img src={generateImageMediaUrl(item?.icon?.path)} alt=""/>
                        </div>
                        <div className={'homeService-card-title'}>
                            {item?.title}
                        </div>
                        <div className={'homeService-card-description'}>
                            {item?.description}
                        </div>
                        <div className={'homeService-card-more'} onClick={() => toggleModalOpen(item)}>
                            {staticTexts?.homepage_more_info_btn} <ArrIcon/>
                        </div>
                    </div>
                })
            }
            {
                services?.blocks?.length > 4 ? <Link to={'/services'} className={'homeService-more-block'}>
                    {staticTexts?.homepage_more_service} <span>
                    <ArrIcon/></span>
                </Link> : ''
            }
        </div>
        <ServiceModal toggleModalOpen={toggleModalOpen} modalOpen={modalOpen} service={service}/>
    </div>
}

export default HomeServices