import { GENERAL_CONSTS } from "../constants";
import {shopProduct} from "../actions";

export const initialState = {
    languages: [],
    selectedLanguage: null,
    staticTexts: {},
    blogs: {
        itemsList: [],
        hasMore: false,
        category: undefined,
    },
    products: {
        itemsList: [],
        hasMore: false,
        category: undefined,
    },
    lastNews: {
        itemsList: [],
        hasMore: false,
        category: undefined,
    },
    shopProducts: {
        itemsList: [],
        count: false,
        checkedId:[],
    },
    isOpen:undefined,
    blogPage: {},
    partners: [],
    sponsors: [],
    home:undefined,
    countryCode: 'en',
};

export default (state = initialState, action) => {
    switch ( action.type ) {
        case GENERAL_CONSTS.GET_LANGUAGES:
            const languages = action.payload.languages
            const countryCode = action.payload.countryCode
            const enLanguage = languages.find(lg => lg.code === 'en');
            const selectedLanguage = state.selectedLanguage && state.selectedLanguage.id
                && languages.find(lg => lg.code === state.selectedLanguage.code);

            const countryLanguage = languages.find(lg => lg.code === countryCode);

            const newSelectedLanguage = selectedLanguage ? selectedLanguage :
                (countryLanguage ? countryLanguage : (enLanguage ? enLanguage : languages[0]));


            const newState = {
                ...state,
                languages,
                selectedLanguage: newSelectedLanguage,
            };
            if (state.staticTexts && state.staticTexts[newSelectedLanguage.code]) {
                newState.staticTexts = state.staticTexts[newSelectedLanguage.code]
            }

            return newState;
        case GENERAL_CONSTS.CHANGE_LANGUAGE:
            return {
                ...state,
                selectedLanguage: action.payload,
            };
        case GENERAL_CONSTS.GET_STATIC_TEXTS:
            return {
                ...state,
                staticTexts: action.payload[state.selectedLanguage?.code || 'en']
            };
        case GENERAL_CONSTS.GET_BLOGS:
            return {
                ...state,
                blogs: {
                        count: action.payload.data.newsCount,
                        itemsList:action.payload.reset ? action.payload.data.news : [...state.data.blogs, ...action.payload.data.news],
                        hasMore: action.payload.hasMore
                }
            };
        case GENERAL_CONSTS.GET_PRODUCT:
            return {
                ...state,
                products: {
                        count: action.payload.data.productsCount,
                        itemsList:action.payload.reset ? action.payload.data.products : [...state.data.products, ...action.payload.data.products],
                        hasMore: action.payload.hasMore
                }
            };
        case GENERAL_CONSTS.GET_PARTNERS:
            return {
                ...state,
                partners: action.payload
            };
        case GENERAL_CONSTS.GET_HOME:
            // console.log(action.payload,'action.payloadaction.payload')
            return {
                ...state,
                home: action.payload
            };
        case GENERAL_CONSTS.GET_SPONSORS:
            return {
                ...state,
                sponsors: action.payload
            };
        case GENERAL_CONSTS.GET_LAST_NEWS:
            return {
                ...state,
                lastNews:{
                    count: action.payload.data.newsCount,
                    itemsList:action.payload.reset ? action.payload.data.news : [...state.data.blogs, ...action.payload.data.news],
                    hasMore: action.payload.hasMore
                },
            };
        case GENERAL_CONSTS.SHOP_PRODUCT:
            return {
                ...state,
                shopProducts:{
                    count: action.payload.count || null,
                    itemsList:action.payload?.items,
                    checkedId: action.payload?.checkedId,
                    isOpen:action?.payload?.isOpen || undefined
                },
            };
        case GENERAL_CONSTS.IS_OPEN:
            return {
                ...state,
                isOpen:action?.payload
            };
        default:
            return state;
    }
}
