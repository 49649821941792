import React, {useState} from "react";

//Import Assets
import "./NewsCard.scss";
import {generateImageMediaUrl} from "../../../../utils/generateMediaUrl";
import {ShareIcon} from "../../../../assets/images";
import NewsModal from "../../modal/newsModal/NewsModal";
import moment from "moment";

function NewsCard(props){
    const {item} = props
    const [modalOpen,setModalOpen] = useState(false)
    const [news,setNews] = useState()

    function toggleModalOpen(news){
        setNews(news)
        setModalOpen(!modalOpen)
    }

    return <div className={'news-block'} onClick={() => toggleModalOpen(item)}>
        <div className={'news-block-img'}>
            <img src={generateImageMediaUrl(item?.mediaMain?.path)}/>
        </div>
        <div className={'news-block-info'}>
            <div className={'news-date'}>
                {moment(item?.date).format('ll')}
            </div>
            <div className={'news-title-wrapper'}>
                <div className={'news-block-title'}>
                    {item?.title}
                </div>
                <div className={'news-block-link'} onClick={() => toggleModalOpen()}>
                    <ShareIcon/>
                </div>
            </div>
            <div className={'news-block-description'}>
                {item?.description}
            </div>
        </div>
        <NewsModal toggleModalOpen={toggleModalOpen} modalOpen={modalOpen} news={news}/>
    </div>
}
export default NewsCard